import React from 'react';
import AdList from "../../components/AdList/AdList";
import {CarAdInstance} from "../../types";

function HomePage(props: {data: CarAdInstance[]}) {
    return (
        <div>
            <AdList data={props.data} />
        </div>
    );
}

export default HomePage;