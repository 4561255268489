import React, {useEffect, useState} from 'react';
import HomePage from '../../pages/HomePage/HomePage';
import {CarAdInstance} from "../../types";

function toCamel(s: string)  {
    return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
    });
}

function isObject(o: any) {
    return o === Object(o) && !Array.isArray(o) && typeof o !== 'function';
}

function keysToCamel(o: any): any {
    if (isObject(o)) {
        const n : any = {};

        Object.keys(o)
            .forEach((k) => {
                n[toCamel(k)] = keysToCamel(o[k]);
            });

        return n;
    } else if (Array.isArray(o)) {
        return o.map((i) => {
            return keysToCamel(i);
        });
    }

    return o;
}

function parseData(rawData: any[]) : CarAdInstance[] {
    const data : any[] = keysToCamel(rawData);

    data.forEach(value => {
        value.createdAt = new Date(value.createdAt);
        value.lastScrapedAt = new Date(value.lastScrapedAt);
        value.model = value.model?.toLowerCase()
        value.manufacturer = value.manufacturer?.toLowerCase()
    })

    return data;
}

function App() {
    const [data, setData] = useState<CarAdInstance[] | null>(null);

    useEffect(() => {
        const doFetch = async () => {
            const result = await fetch('https://cars-data.nepozitek.cz/database.json');
            const json = await result.json();

            setData(parseData(json));
        }
        doFetch();
    }, []);

    if (data === null) {
        return <div>
            loading..
        </div>
    }

    return (
        <HomePage data={data}/>
    );
}

export default App;