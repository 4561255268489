import React, {useState} from 'react';
import {CarAdInstance, FilterValues} from "../../types";
import AdListItem from "../AdListItem/AdListItem";
import styles from './AdList.module.css';
import AdListFilter from "../AdListFilter/AdListFilter";

function applyFilter(data: CarAdInstance[], filter: FilterValues) : CarAdInstance[] {
    let filteredData : CarAdInstance[] = data;

    if (filter.manufacturer !== '') {
        const value = filter.manufacturer;
        filteredData = filteredData.filter(x => x.manufacturer === value);
    }

    if (filter.model !== '') {
        const value = filter.model;
        filteredData = filteredData.filter(x => x.model === value);
    }

    if (filter.title !== '') {
        const value = filter.title;
        filteredData = filteredData.filter(x => x.title.includes(value));
    }

    if (filter.priceFrom !== '') {
        const value = parseInt(filter.priceFrom);
        filteredData = filteredData.filter(x => x.price >= value);
    }

    if (filter.priceTo !== '') {
        const value = parseInt(filter.priceTo);
        filteredData = filteredData.filter(x => x.price <= value);
    }

    if (filter.kilometersFrom !== '') {
        const value = parseInt(filter.kilometersFrom);
        filteredData = filteredData.filter(x => x.kilometers >= value);
    }

    if (filter.kilometersTo !== '') {
        const value = parseInt(filter.kilometersTo);
        filteredData = filteredData.filter(x => x.kilometers <= value);
    }

    if (filter.yearFrom !== '') {
        const value = parseInt(filter.yearFrom);
        filteredData = filteredData.filter(x => x.year >= value);
    }

    if (filter.yearTo !== '') {
        const value = parseInt(filter.yearTo);
        filteredData = filteredData.filter(x => x.year <= value);
    }

    if (filter.isAvailable !== false && filter.isAvailable.length === 1) {
        const isAvailable = filter.isAvailable[0] === 'true';
        filteredData = filteredData.filter(x => x.isAvailable === isAvailable);
    }

    if (filter.sources !== false && filter.sources.length > 0) {
        const sources = filter.sources;
        filteredData = filteredData.filter(x => sources.indexOf(x.source) !== -1);
    }

    return filteredData;
}

function getSources(data: CarAdInstance[]): string[] {
    const sources : string[] = [];

    data.forEach(ad => {
        if (sources.indexOf(ad.source) === -1) {
            sources.push(ad.source);
        }
    })

    return sources;
}

function getModels(data: CarAdInstance[]): { [key: string]: string[] } {
    const models : { [key: string]: string[] } = {};

    data.forEach(ad => {
        if (!ad.manufacturer) {
            return;
        }

        if (!(ad.manufacturer in models)) {
            models[ad.manufacturer] = [];
        }

        const currentModels = models[ad.manufacturer]
        if (currentModels.indexOf(ad.model) === -1) {
            currentModels.push(ad.model);
        }
    });

    Object.values(models).forEach(currentModels => {
        currentModels.sort();
    });

    return models;
}

function AdList(props: {data: CarAdInstance[]}) {
    const {data} = props;
    const [filteredData, setFilteredData] = useState(data);
    const [sources] = useState(getSources(data));
    const [models] = useState(getModels(data));

    const onSubmit = (filterData: FilterValues) => {
        console.log(filterData);
        setFilteredData(applyFilter(data, filterData));
    }

    return (
        <div>
            <AdListFilter onSubmit={onSubmit} sources={sources} models={models} />
            <div className={styles.list}>
                <div className={styles.header}>
                    <div className={styles.total_count}>
                        Nalezeno {filteredData.length} inzerátů
                    </div>
                </div>
                <div>
                    {filteredData
                        .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
                        .map(ad => <AdListItem ad={ad} />)}
                </div>
            </div>
        </div>
    );
}

export default AdList;