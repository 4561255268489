import React, {useState} from "react";
import styles from "./AdListFilter.module.css";
import {Button, Col, Form, Row, InputGroup} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {FilterValues} from "../../types";

function AdListFilter(props: {onSubmit: (data: FilterValues) => void, sources: string[], models : { [key: string]: string[] }}) {
    const { onSubmit, sources, models } = props;
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();
    const [currentManufacturer, setCurrentManufacturer] = useState("");
    const onChangeManufacturer = (e: any) => {
        setCurrentManufacturer(e.target.value);
        setValue('model', '');
    }

    return (
        <div className={styles.wrapper}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className={styles.row}>
                    <Form.Label>Výrobce</Form.Label>
                    <Form.Group as={Col}>
                        <Form.Select {...register('manufacturer')} onChange={onChangeManufacturer}>
                            <option value="">Vybrat výrobce</option>
                            {Object.keys(models).sort().map(manufacturer => <option value={manufacturer}>{manufacturer}</option>)}
                        </Form.Select>
                    </Form.Group>
                </Row>
                <Row className={styles.row}>
                    <Form.Label>Modely</Form.Label>
                    <Form.Group as={Col}>
                        <Form.Select {...register('model')}>
                            <option value="">{currentManufacturer ? "Vybrat model" : "Nejdříve vyberte výrobce"} </option>
                            {currentManufacturer && models[currentManufacturer].map(model => <option value={model}>{model}</option>)}
                        </Form.Select>
                    </Form.Group>
                </Row>
                <Row className={styles.row}>
                    <Form.Label>Hledat v názvu</Form.Label>
                    <Form.Group as={Col}>
                        <Form.Control {...register('title')} />
                    </Form.Group>
                </Row>
                <Row className={styles.row}>
                    <Form.Label>Cena</Form.Label>
                    <Form.Group as={Col}>
                        <InputGroup className="mb-2" size="sm">
                            <Form.Control placeholder="Od" {...register('priceFrom')} autoComplete="off" />
                            <InputGroup.Text>Kč</InputGroup.Text>
                        </InputGroup>

                    </Form.Group>
                    <Form.Group as={Col}>
                        <InputGroup className="mb-2" size="sm">
                            <Form.Control placeholder="Do" {...register('priceTo')} autoComplete="off" />
                            <InputGroup.Text>Kč</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Row>
                <Row className={styles.row}>
                    <Form.Label>Stav tachometru</Form.Label>
                    <Form.Group as={Col}>
                        <InputGroup className="mb-2" size="sm">
                            <Form.Control placeholder="Od" {...register('kilometersFrom')} />
                            <InputGroup.Text>km</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <InputGroup className="mb-2" size="sm">
                            <Form.Control placeholder="Do km" {...register('kilometersTo')} />
                            <InputGroup.Text>km</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Row>
                <Row className={styles.row}>
                    <Form.Label>Rok výroby</Form.Label>
                    <Form.Group as={Col}>
                        <Form.Control placeholder="Od" {...register('yearFrom')} />
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Control placeholder="Do" {...register('yearTo')} />
                    </Form.Group>
                </Row>
                <Row className={styles.row}>
                    <Form.Label>Stav</Form.Label>
                    <Form.Group as={Col}>
                        <Form.Check
                            type="checkbox"
                            value="true"
                            label="K dispozici"
                            {...register('isAvailable')}
                        />
                        <Form.Check
                            type="checkbox"
                            value="false"
                            label="Prodáno"
                            {...register('isAvailable')}
                        />
                   </Form.Group>
                </Row>
                <Row className={styles.row}>
                    <Form.Label>Zdroje</Form.Label>
                    <Form.Group as={Col}>
                        {sources.map(source => <Form.Check
                            type="checkbox"
                            value={source}
                            label={source}
                            {...register('sources')}
                            key={source}
                        />)}
                    </Form.Group>
                </Row>
                <Button className={styles.search} type="submit">Vyhledat</Button>
            </Form>
        </div>
    );
}

export default AdListFilter;