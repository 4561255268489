import React from 'react';
import {CarAdInstance} from "../../types";
import styles from './AdListItem.module.css';
import TimeAgo from 'react-timeago';
// @ts-ignore
import czechStrings from 'react-timeago/lib/language-strings/cs';
// @ts-ignore
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';

const formatter = buildFormatter(czechStrings)

function numberWithSpaces(x: number): string {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function AdListItem(props : {ad : CarAdInstance}) {
    const { ad } = props;

    return (
        <div className={styles.wrapper}>
            <div className={styles.image} style={{ backgroundImage: `url(${ad.imageUrl})` }} />
            <div className={styles.content}>
                <div className={styles.title}>{ad.title} {!ad.isAvailable && <span className={styles.sold}>(Prodáno)</span>}</div>

                <div className={styles.info}>
                    <span>{numberWithSpaces(ad.kilometers)} km</span>
                    <span>{ad.year}</span>
                    <span>{ad.source}</span>
                    {ad.manufacturer && <span>
                        Výrobce: {ad.manufacturer}, model: {ad.model}
                    </span>}
                </div>

                <div className={styles.right_side}>
                    <div className={styles.price}>{numberWithSpaces(ad.price)} Kč</div>
                    <a href={ad.url} target="_blank">Detail</a>
                </div>

                <div className={styles.footer}>
                    <span>Vytvořeno: <TimeAgo date={ad.createdAt} formatter={formatter} />{ad.isFromInitialScrape && <span>*</span>}</span>
                    <span>Aktualizováno: <TimeAgo date={ad.lastScrapedAt} formatter={formatter} /></span>
                </div>
            </div>
        </div>
    );
}

export default AdListItem;